<template>
  <main class="work">
    <div class="work-c">
      <section class="l-work-subsection align-center" id="executive-recruitment" data-scroll-section>
        <div class="main-features">
          <h1 class="title title-big title-general" data-scroll="fade-up" data-scroll-delay="1">The Work: Professional Development</h1>
          <ul class="main-list">
            <li class="main-item" data-scroll="fade-up" data-scroll-delay="1">
              <div class="c-card c-card-lines-white">
                <figure class="icon"><img alt="exchange" :src="require(`@/assets/work/icon-client-profile.svg`)" /></figure>
                <h3 class="subtitle">Client Profile</h3>
                <div class="text-c">
                  <p class="text">&bull; US $9 billion international consumer products company</p>
                  <p class="text">&bull; Family-owned with 2 primary divisions, equal in size</p>
                  <p class="text">&bull; Each division run by an independent President, both reporting to worldwide CEO, a family member</p>
                </div>
              </div>
            </li>
            <li class="main-item" data-scroll="fade-up" data-scroll-delay="2">
              <div class="c-card c-card-lines-white">
                <figure class="icon"><img alt="exchange" :src="require(`@/assets/work/icon-client-situation.svg`)" /></figure>
                <div class="text-c">
                  <h3 class="subtitle">Client Situation</h3>
                  <div class="text-c">
                    <p class="text">Business was leveraging innnovation around the world to outpace competitors, but was lagging in Asia.</p>
                    <p class="text">The Company had made multiple new hires in the region, but everything else seemed “business as usual”.</p>
                    <p class="text">Core question: why hasn't the Company's successful model worked as well in Asia?</p>
                  </div>
                </div>
              </div>
            </li>
            <li class="main-item" data-scroll="fade-up" data-scroll-delay="3">
              <div class="c-card c-card-lines-white">
                <figure class="icon"><img alt="exchange" :src="require(`@/assets/work/icon-diagnostic-finding.svg`)" /></figure>
                <div class="text-c">
                  <h3 class="subtitle">Diagnostic Finding</h3>
                  <div class="text-c">
                    <p class="text">Our insights were obtained in an in-depth discovery process with a broad group of company executives.</p>
                    <p class="text">The issue was found to be corporate culture, where collegiality and conflict avoidance had led to atrophy and an inability to face or address even serious problems.</p>
                    <p class="text">The business' level of team activation was very low, never achieving a shared sense of mission or teamwork, despite a congenial facade.</p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div class="bespoke-solution align-center">
          <h1 class="title title-small title-general" data-scroll="fade-up" data-scroll-delay="1"><span>Ascentador</span>Bespoke Solution</h1>
          <h3 class="highlight highlight-subsentence" data-scroll="fade-up" data-scroll-delay="2">Drawing on our expertise in business leadership, change management, and executive alignment, we proposed a Professional Development Process that organized employees by underlying potential and provided each with suitable roadmaps for growth and development, so long as they were able to collaborate with others.</h3>
          <ul class="main-list">
            <li class="main-item" data-scroll="fade-up" data-scroll-delay="1">
              <div class="c-card">
                <span class="number">1</span>
                <h3 class="subtitle">Skills</h3>
                <div class="text-c">
                  <p class="text">The most straightforward aim of the professional development process, this level is focused on expanding technical and/or functional skills.</p>
                </div>
              </div>
            </li>
            <li class="main-item" data-scroll="fade-up" data-scroll-delay="2">
              <div class="c-card">
                <span class="number">2</span>
                <div class="text-c">
                  <h3 class="subtitle">Competencies</h3>
                  <div class="text-c">
                    <p class="text">Elevated beyond mere skill, competency development includes a focus on strategic agility, organizing & planning, decision-making, problem-solving, and managing performance. Such development is often achieved through temporary, cross-functional assignments, in which a given professional is tasked with contributing in a series of innovative ways.</p>
                  </div>
                </div>
              </div>
            </li>
            <li class="main-item" data-scroll="fade-up" data-scroll-delay="3">
              <div class="c-card">
                <span class="number">3</span>
                <div class="text-c">
                  <h3 class="subtitle">Leadership</h3>
                  <div class="text-c">
                    <p class="text">The purpose of leadership development is to ensure that a small cadre of executives is sufficiently schooled in relevant competencies - -  including dealing with ambiguity, organizational communication, priority setting, managing diversity, team formation and activation, etc. - -  so as to be ready to assume senior leadership positions, whether as part of a formal succession plan or not.  This type of development is, by its nature, reserved for the very few.</p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
export default {
  name: 'ProfessionalDevelopment',
  metaInfo() {
      return { 
          title: "Ascentador | The Work",
          meta: [
              { name: 'description', content:  'The Diagnostic Process brings together a team of expert consultants and key client stakeholders prior to launching any project. Using a proprietary form of guided questioning, the goal is to gain insight, uncover a lack of alignment and reveal unspoken agendas before laying the groundwork for the development of lateral solutions.'},
          ]
      }
  },
  data() {
    return {};
  },
  mounted() {
    this.$emit('view-mounted');
  },
};
</script>
